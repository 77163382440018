<template>
   <div>
        <div v-if="loading">
           <Loading />
       </div>
       <div v-else>
            <div class="license-body" style="margin: auto; padding: 1rem; width: 850px; height: auto; background-color: #fff;">
            <div class="text-right">
                <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                </b-button>
            </div>
            <div style="padding: 3px; border: 1px solid #444444;">
                <div style="padding: 3px; border: 2px solid #444444;">
                    <div style="padding: 1.5rem; border: 1px solid #444444;">
                        <!-- license- header -->
                        <div style="text-align: center;">
                            <img style="max-width: 120px; margin-bottom: .5rem;"
                                src="@/assets/images/logo.png">
                            <p style="font-weight: 600; font-size: 16px;">{{ $t('globalTrans.government_republic_of_bangladesh') }}</p>
                            <p style="font-weight: 600; font-size: 16px; margin-bottom: .5rem;">{{ $t('teaGardenService.bangladesh_tea_board') }}</p>
                            <p style="">{{ $t('teaGardenService.tea_board_address') }}</p>
                            <!-- <p style="">{{ $t('teaGardenService.tea_board_address_2') }}</p> -->
                            <p style="font-weight: 600;">{{ 'www.teaboard.gov.bd' }}</p>
                        </div>
                        <div>
                          <b-row>
                            <b-col>
                              <p style="font-weight: 600; margin-top: 1rem;">
                                {{ $t('teaGardenPanel.license_number') }}:
                                {{ appDetail.broker_registration_no ? $n(appDetail.broker_registration_no, { useGrouping: false }) : '' }}</p>
                            </b-col>
                            <b-col style="text-align: right" v-if="appDetail.application?.expired_date">
                              <p style="font-weight: 600; margin-top: 1rem;">{{ $t('globalTrans.date')}}: {{ formatted(appDetail.application.expired_date) }}</p>
                            </b-col>
                          </b-row>
                        </div>
                        <p style="font-weight: 600; margin-top: 2rem;">{{ $t('teaGardenService.subject')}}: {{ currentLocale === 'en' ? appDetail.company_name_en + ' grant of Tea Export license.' : appDetail.company_name_bn + ' কর্তৃক চা রপ্তানির অনুমতি প্রদান।' }}</p>
                        <!-- license body -->
                        <div style="margin-top: 1rem; text-align: center;">
                            <p class="text-justify" v-if="currentLocale === 'bn'">
                                চা আইন, ২০১৬ এর ধারা ১৯ ও ২১ এর অধীন প্রদত্ত ক্ষমতাবলে {{ appDetail.company_name_bn }}, রপ্তানি নিবন্ধন নং: {{appDetail.broker_registration_no}} কে নিম্নে বর্ণিত  {{appDetail.tea_quantity}} কেজি চা আগামী {{appDetail.last_export_date | dateFormat}} তারিখের মধ্যে {{getExportCountryName(appDetail.export_country_id)}} রপ্তানি করার জন্য অনুমতি প্রদান করা হলো: <br> <br>
                            </p>
                            <p class="text-justify" v-else>
                                By virtue of Section 19 & 21 of the Tea Act, 2016, {{ appDetail.company_name_en }}, under Export Registration No. {{appDetail.broker_registration_no}}, {{appDetail.tea_quantity}} Kgs Tea dated {{appDetail.last_export_date | dateFormat}} {{getExportCountryName(appDetail.export_country_id)}} is allowed to export in: <br> <br>
                            </p>
                            <!-- details data show -->
                            <b-table-simple hover small bordered>
                                <b-thead>
                                <b-tr>
                                    <b-th>{{ $t('teaGardenPanel.contract_no') }}</b-th>
                                    <b-th>{{ $t('teaGardenConfig.grade_name') }}</b-th>
                                    <b-th>{{ $t('teaGardenPanel.package') }}</b-th>
                                    <b-th>{{ $t('teaGardenPanel.weight') }} ({{ $t('globalTrans.kg') }})</b-th>
                                    <b-th>{{ $t('teaGardenPanel.export_price') }} ({{ $t('globalTrans.tk_sign') }})</b-th>
                                </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <template v-if="appDetail.details && Object.keys(appDetail.details).length > 0">
                                        <b-tr v-for="(item, index) in appDetail.details" :key="index">
                                            <!-- contract no -->
                                            <b-td style="vertical-align: middle;" :rowspan="appDetail.details.length" v-if="index === 0">
                                                {{ $t('teaGardenPanel.tt_no') }} {{ currentLocale === 'en' ? appDetail.tt_no_en : appDetail.tt_no_bn }} <br>
                                                {{ $t('globalTrans.date') }} {{ appDetail.contract_date | dateFormat }}
                                            </b-td>
                                            <!-- tea grade name -->
                                            <b-td>
                                                {{ getTeaGradeName(item.made_tea_grage_id) }}
                                            </b-td>
                                            <!-- package -->
                                            <b-td>
                                                {{ $n(item.package) }}
                                            </b-td>
                                            <!-- weight -->
                                            <b-td>
                                            {{ $n(item.weight) }}
                                            </b-td>
                                            <!-- export price -->
                                            <b-td>
                                                {{ $n(item.export_price) }}
                                            </b-td>
                                        </b-tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                            <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                    </template>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                        <!-- license footer -->
                        <table style="margin-top: 3rem;">
                            <tr>
                                <td style="width: 100%;">
                                    <div v-if="appDetail.application.service_instruction">
                                        <strong>{{ $t('teaGardenPanel.conditions') }}:</strong>
                                            <p v-html="currentLocale === 'en' ? appDetail.application.service_instruction?.certificate_instructions_en : appDetail.application.service_instruction?.certificate_instructions_bn" style="list-style-type: bengali; list-style-position: inside; font-size: 11px;"></p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                              <td style="width: 50%; vertical-align: top;"></td>
                              <td style="width: 30%; margin-left: 10px; padding-left: 10px; vertical-align: top;" class="text-center">
                                  <div>
                                      <img v-if="appDetail.application.document_signatory && signatory_user_info.signature && appDetail.application.document_signatory.show_signature" :src="authServiceBaseUrl + 'storage/' + signatory_user_info.signature" width="100px" alt="">
                                      <img v-else-if="approve_user_info.signature" :src="authServiceBaseUrl + 'storage/' + approve_user_info.signature" width="100px" alt="">
                                      <p v-if="appDetail.application.document_signatory || appDetail.application.approver_name_en">{{ $t('globalTrans.name') }}:
                                          <span v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.name_en && appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? signatory_user_info.text_en : signatory_user_info.text_bn }}</span>
                                          <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? approve_user_info.text_en : approve_user_info.text_bn }}</span>
                                      </p>
                                      <p v-if="appDetail.application.document_signatory || appDetail.application.approver_designation_en">{{ $t('globalTrans.designation') }}:
                                          <span v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.designation_en && appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? signatory_user_info.designation_en : signatory_user_info.designation_bn }}</span>
                                          <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? approve_user_info.designation_en : approve_user_info.designation_bn }}</span>
                                      </p>
                                      <p v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.email">{{ $t('globalTrans.email') }}:
                                          <span v-if="appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ signatory_user_info.email }}</span>
                                          <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ approve_user_info.email }}</span>
                                      </p>
                                      <p v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.mobile">{{ $t('globalTrans.mobile') }}:
                                          <span v-if="appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;"><span v-if="signatory_user_info.mobile">{{ EngBangNum(signatory_user_info.mobile) }}</span></span>
                                          <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;"><span v-if="approve_user_info.mobile">{{ EngBangNum(approve_user_info.mobile) }}</span></span>
                                      </p>
                                      <p>{{ $t('globalTrans.date') }} <span
                                              style="text-decoration-style: dotted; text-decoration-line: underline;">{{ appDetail.application.approved_date | dateFormat }}</span>
                                      </p>
                                  </div>
                              </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            </div>
       </div>
       <b-col md="12" class="text-right">
        <b-button variant="primary " class="btn-sm" @click="back()">{{ $t('globalTrans.back') }}</b-button>
        </b-col>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
  import { dateFormat } from '@/utils/fliter'
  import Loading from './loading/Details.vue'
  import { AuthUserInfo } from '../../../api/routes'
  import { mapGetters } from 'vuex'
  export default {
    props: ['items'],
    components: {
        Loading
    },
    created () {
      this.getAppDetail()
    },
    data () {
        return {
            authServiceBaseUrl: authServiceBaseUrl,
            appDetail: {},
            loading: false,
            approve_user_info: {},
            signatory_user_info: {}
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        },
        appId () {
            return this.$route.params.id
        },
        madeTeaGardeList () {
        if (this.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.teaGarden.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
        } else {
            return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
        }
        },
        getGardenList () {
        if (this.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.teaGarden.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        } else {
            return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        }
        },
        portNameList () {
            return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'চট্টগ্রাম নৌ বন্দর' : 'Chattogram Sea Port' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'মংলা নৌ বন্দর' : 'Mongla Sea Port' },
            { value: 3, text: this.$i18n.locale === 'bn' ? 'হযরত শাহজালাল আন্তর্জাতিক বিমান বন্দর' : 'Hazrat Shahjalal International Airport' },
            { value: 4, text: this.$i18n.locale === 'bn' ? 'শাহ্ আমানাত আন্তর্জাতিক বিমান বন্দর' : 'Shah Amanat International Airport' },
            { value: 5, text: this.$i18n.locale === 'bn' ? 'ওসমানী আন্তর্জাতিক বিমান বন্দর' : 'Osmani International Airport' },
            { value: 6, text: this.$i18n.locale === 'bn' ? 'কক্সবাজার আন্তর্জাতিক বিমান বন্দর' : "Cox's Bazar International Airport" }
            ]
        }
    },
    methods: {
        EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
        back () {
            this.$router.go(-1)
        },
        async getAppDetail () {
            this.loading = true
            const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/license-application/export-license-show' + '/' + this.appId)
            if (result.success) {
                this.appDetail = result.data
                if (this.appDetail.application.document_signatory && this.appDetail.application.document_signatory.user_id) {
                    this.getSignatoryUserInfo(this.appDetail.application.document_signatory.user_id)
                }
                if (this.appDetail.application.approver_id) {
                    this.getApproveUserInfo(this.appDetail.application.approver_id)
                }
            }
            this.loading = false
        },
        async pdfExport () {
            this.loading = true
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId, show_certificate: true })
            const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 31)
            if (service !== undefined) {
                if (service.office_type_id) {
                params.office_type_id = service.office_type_id
                }
                if (service.office_id) {
                params.office_id = service.office_id
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/license-application/export-license-view-pdf', params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            this.loading = false
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        async getSignatoryUserInfo (id) {
            const result = await RestApi.getData(authServiceBaseUrl, AuthUserInfo + id)
            if (result.success) {
                this.signatory_user_info = result.data
                if (this.signatory_user_info) {
                    this.getDesignationNameSignatory(this.signatory_user_info.designation_id)
                    this.getOfficeNameSignatory(this.signatory_user_info.office_id)
                }
            }
        },
        async getApproveUserInfo (id) {
            const result = await RestApi.getData(authServiceBaseUrl, AuthUserInfo + id)
            if (result.success) {
                this.approve_user_info = result.data
                if (this.approve_user_info) {
                    this.getDesignationNameApprove(this.approve_user_info.designation_id)
                    this.getOfficeNameApprove(this.approve_user_info.office_id)
                }
            }
        },
        getDesignationNameSignatory (id) {
            const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
            if (obj) {
                this.signatory_user_info.designation_en = obj.text_en
                this.signatory_user_info.designation_bn = obj.text_bn
            }
            return true
        },
        getOfficeNameSignatory (id) {
            const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
            if (obj) {
                this.signatory_user_info.office_en = obj.text_en
                this.signatory_user_info.office_bn = obj.text_bn
            }
            return true
        },
        getDesignationNameApprove (id) {
            const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
            if (obj) {
                this.approve_user_info.designation_en = obj.text_en
                this.approve_user_info.designation_bn = obj.text_bn
            }
            return true
        },
        getOfficeNameApprove (id) {
            const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
            if (obj) {
                this.approve_user_info.office_en = obj.text_en
                this.approve_user_info.office_bn = obj.text_bn
            }
            return true
        },
        formatted (value) {
            return new Date(value).toLocaleString(this.$i18n.locale + '-GB', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long' // numeric, 2-digit, long, short, narrow
            })
        },
        add5years (currentDate) {
          var date = new Date(currentDate)
          date.setFullYear(date.getFullYear() + 5)
          var newDateString = date.toISOString().slice(0, 10)
          var parts = newDateString.split('-')
          return dateFormat(parts[2] + '-' + parts[1] + '-' + parts[0])
        },
        getTeaGradeName (id) {
            const obj = this.madeTeaGardeList.find(item => item.value === id)
            if (obj) {
                return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
            }
        },
        getExportCountryName (id) {
        const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === id)
        if (obj) {
                return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
        }
        },
        getPortNames (ids) {
        if (Array.isArray(ids)) {
            var text = []
            ids.forEach(id => {
            const obj = this.portNameList.find(item => item.value === parseInt(id))
            if (obj) {
                var value = obj?.text
                text.push(value)
            }
            })
            return text.join(', ')
        }
        }
    }
}
</script>
<style>
    .license-body {
        background-image: url('../../../../../../assets/images/btb-watermark.png');
        background-size: initial;
        background-position: center;
        background-repeat: no-repeat;
    }
    .flex-container {
      display: flex;
    }
</style>
